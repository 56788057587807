/*! base.css styles */

p {
  line-height: inherit;
  margin: 0;
}

ul,
ul li,
ol,
ol li {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0;
  line-height: inherit;
}

input[type="text"],
input[type="email"],
input[type="password"] {
  margin: 0;
  background: #fff;
  box-shadow: none;
  border: 0;
  color: inherit;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  color: #333;
}

label {
  color: inherit;
}

label:focus {
  outline: none;
}

a,
a:hover,
a:active,
a:focus {
  outline: none;
  font-weight: inherit;
  font-family: inherit;
  color: inherit;
  text-decoration: none;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(0, 0, 0, 0.8);
  font-style: italic;
  font-weight: normal;
  letter-spacing: -0.02em;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(0, 0, 0, 0.8);
  font-style: italic;
  font-weight: normal;
  opacity: 1;
  letter-spacing: -0.02em;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(0, 0, 0, 0.8);
  font-style: italic;
  font-weight: normal;
  letter-spacing: -0.02em;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(0, 0, 0, 0.8);
  font-style: italic;
  font-weight: normal;
  opacity: 1;
  letter-spacing: -0.02em;
}

.inverted::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(255, 255, 255, 0.8);
}

.inverted::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(255, 255, 255, 0.8);
}

.inverted:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(255, 255, 255, 0.8);
}

.inverted:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(255, 255, 255, 0.8);
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
}

.fa-fw {
  width: 1.28571429em;
  text-align: center;
}

/* Columns */

@media only screen and (max-width: 576px) {
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }

  .row {
    margin-left: -10px;
    margin-right: -10px;
  }

  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xs-1,
  .col-xs-10,
  .col-xs-11,
  .col-xs-12,
  .col-xs-2,
  .col-xs-3,
  .col-xs-4,
  .col-xs-5,
  .col-xs-6,
  .col-xs-7,
  .col-xs-8,
  .col-xs-9 {
    max-width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

/* App Styles */

html {
  height: 100%;
  min-height: 100%;

  max-width: 100%; 
  overflow-x: hidden;
}

body {
  font-family: Montserrat, "Montserrat-fallback-700", Helvetica, arial,
    sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  height: 100%;
  min-height: 100%;
  font-size: 16px;
  color: #222;
  background: #fff;
  background-size: cover;

  max-width: 100%; 
}

button {
  font-weight: inherit;
  font-family: inherit;
}

input {
  font-weight: inherit;
  font-family: inherit;
}

optgroup {
  font-weight: inherit;
  font-family: inherit;
}

select {
  font-weight: inherit;
  font-family: inherit;
}

textarea {
  font-weight: inherit;
  font-family: inherit;
}

/* Range Slider */

.input-range__slider {
  background-color: #f70564; /*! important */
  border-color: #f70564; /*! important */
}

.input-range__track--active {
  background-color: #1e376d; /*! important */
  border-color: #1e376d; /*! important */
}

.input-range__label--min {
  font-size: 0.8em; /*! important */
  color: #ccc; /*! important */
}

.input-range__label--max {
  font-size: 0.8em; /*! important */
  color: #ccc; /*! important */
}

.input-range__label--value {
  color: #999; /*! important */
}

/* Template */

/* TODO: Move */

.logo {
  position: relative;
  line-height: 1;
  margin: 0;
  padding: 0;
  font-size: 1em;
}

.logo .icon {
  width: 2.2em;
  position: relative;
  height: 2.2em;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.2em;
  transition: all 800ms cubic-bezier(0.465, 0.183, 0.153, 0.946);
  transform: scale(0, 0);
  opacity: 0;
}

body .logo .icon {
  transform: scale(1, 1);
  opacity: 1;
}

.logo .icon .border {
  position: relative;
  background: #f70564;
  padding-bottom: 100%;
  display: block;
  border-radius: 50%;
  transform-origin: 50% 50%;
}

.logo .icon .outer {
  position: absolute;
  display: block;
  height: 0;
  background: #fff;
  border-radius: 50%;
  overflow: hidden;

  left: 0;
  top: 0;

  width: 87%;
  padding-bottom: 87%;

  margin: 6.5% 0 0 6.5%;
}

.logo .icon .inner {
  position: absolute;
  display: block;
  height: 0;
  background: #1e376d;
  border-radius: 50%;

  left: 0;
  top: 0;

  width: 74%; /*  */
  padding-bottom: 74%;

  margin: 14% 0 0 14%;
}

.logo .icon .line {
  position: absolute;
  display: block;
  background: #1e376d;

  left: 0;
  top: 50%;

  width: 28.125%; /* 180 / 640 */
  padding-bottom: 100%;

  margin: 0 0 0 0%;
}

.logo .text {
  vertical-align: middle;
  font-weight: 300;
  text-transform: uppercase;
  line-height: 1;
  transition: all 600ms 1200ms cubic-bezier(0.465, 0.183, 0.153, 0.946);
  margin-left: 0;
  opacity: 0;
  letter-spacing: -0.8em;
}

body .logo .text {
  letter-spacing: 0.5em;
  opacity: 1;
  margin-left: 0.2em;
}

/* This logo */

.logo {
  font-size: 1.2em;
  margin-bottom: 1em;
  text-align: center;
  padding-left: 0.6em;
}

@media screen and (min-width: 40em) {
  .logo {
    margin-bottom: 1em;
    font-size: 1.5em;
  }
}

/* FIX INTERCOM STYLES */
.intercom-lightweight-app-launcher {
  bottom: 60px !important;
}

/* OneTrust */
#ot-sdk-btn.ot-sdk-show-settings,
#ot-sdk-btn.optanon-show-settings {
  border: none !important;
  padding: 0 !important;
  border: none !important;
  padding: 0 !important;
  display: inline !important;
  color: #555 !important;
  background: none !important;
  font-size: 0.875rem !important;
  margin-top: 0.5rem !important;
}

#ot-sdk-btn.ot-sdk-show-settings:hover,
#ot-sdk-btn.optanon-show-settings:hover {
  color: #000 !important;
  background: none !important;
}


/* MUI Table */

body .MuiPaper-root:not(.MuiPopover-paper) {
  background-color: transparent;
}

/* Table header dropdown */
.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  border-radius: 0.75rem;
}


