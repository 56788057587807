:root {
/* raw */
  --raw-border-radius-none: 0;
  --raw-border-radius-l25: 0.25rem;
  --raw-border-radius-l50: 0.5rem;
  --raw-border-radius-l100: 1rem;
  --raw-border-radius-l150: 1.5rem;
  --raw-border-radius-l200: 2rem;
  --raw-border-radius-l300: 3rem;
  --raw-border-radius-l400: 4rem;
  --raw-border-radius-full: 100rem;
  --raw-color-white: #ffffff;
  --raw-color-black: #000000;
  --raw-color-grey: var(--raw-color-grey-l50);
  --raw-color-grey-l0: hsl(0, 0%, 0%);
  --raw-color-grey-l10: hsl(0, 0%, 8%);
  --raw-color-grey-l20: hsl(0, 0%, 20%);
  --raw-color-grey-l30: hsl(0, 0%, 30%);
  --raw-color-grey-l40: hsl(0, 0%, 41%);
  --raw-color-grey-l50: hsl(0, 0%, 50%);
  --raw-color-grey-l60: hsl(0, 0%, 60%);
  --raw-color-grey-l70: hsl(0, 0%, 70%);
  --raw-color-grey-l80: hsl(0, 0%, 83%);
  --raw-color-grey-l90: hsl(0, 0%, 90%);
  --raw-color-grey-l94: hsl(0, 0%, 94%);
  --raw-color-grey-l97: hsl(0, 0%, 97%);
  --raw-color-grey-l100: hsl(0, 0%, 100%);
  --raw-color-neutral: var(--raw-color-neutral-l50);
  --raw-color-neutral-l0: hsl(270, 10%, 0%);
  --raw-color-neutral-l5: hsl(270, 10%, 4%);
  --raw-color-neutral-l10: hsl(270, 10%, 8%);
  --raw-color-neutral-l15: hsl(270, 10%, 15%);
  --raw-color-neutral-l20: hsl(270, 10%, 20%);
  --raw-color-neutral-l30: hsl(272, 9%, 30%);
  --raw-color-neutral-l40: hsl(272, 8%, 41%);
  --raw-color-neutral-l50: hsl(272, 7%, 50%);
  --raw-color-neutral-l60: hsl(270, 9%, 60%);
  --raw-color-neutral-l70: hsl(272, 10%, 70%);
  --raw-color-neutral-l80: hsl(270, 14%, 83%);
  --raw-color-neutral-l90: hsl(273, 18%, 90%);
  --raw-color-neutral-l94: hsl(266, 10%, 94%);
  --raw-color-neutral-l97: hsl(260, 20%, 97%);
  --raw-color-neutral-l100: hsl(300, 0%, 100%);
  --raw-color-rose: var(--raw-color-rose-l50);
  --raw-color-rose-l0: hsl(330, 100%, 0%);
  --raw-color-rose-l10: hsl(330, 100%, 10%);
  --raw-color-rose-l20: hsl(331, 100%, 20%);
  --raw-color-rose-l30: hsl(332, 100%, 30%);
  --raw-color-rose-l40: hsl(334, 100%, 40%);
  --raw-color-rose-l50: hsl(336, 96%, 50%);
  --raw-color-rose-l60: hsl(336, 96%, 60%);
  --raw-color-rose-l70: hsl(336, 100%, 70%);
  --raw-color-rose-l80: hsl(336, 100%, 80%);
  --raw-color-rose-l90: hsl(336, 100%, 90%);
  --raw-color-rose-l94: hsl(336, 100%, 94%);
  --raw-color-rose-l97: hsl(336, 100%, 97%);
  --raw-color-rose-l100: hsl(336, 100%, 100%);
  --raw-color-magenta: var(--raw-color-magenta-l50);
  --raw-color-magenta-l0: hsl(310, 69%, 0%);
  --raw-color-magenta-l10: hsl(310, 69%, 10%);
  --raw-color-magenta-l20: hsl(310, 69%, 20%);
  --raw-color-magenta-l30: hsl(310, 69%, 30%);
  --raw-color-magenta-l40: hsl(310, 69%, 40%);
  --raw-color-magenta-l50: hsl(310, 69%, 50%);
  --raw-color-magenta-l60: hsl(310, 69%, 60%);
  --raw-color-magenta-l70: hsl(310, 69%, 70%);
  --raw-color-magenta-l80: hsl(310, 69%, 80%);
  --raw-color-magenta-l90: hsl(310, 69%, 90%);
  --raw-color-magenta-l94: hsl(310, 69%, 94%);
  --raw-color-magenta-l97: hsl(310, 69%, 97%);
  --raw-color-magenta-l100: hsl(310, 69%, 100%);
  --raw-color-violet: var(--raw-color-violet-l50);
  --raw-color-violet-l0: hsl(272, 100%, 0%);
  --raw-color-violet-l10: hsl(272, 100%, 10%);
  --raw-color-violet-l20: hsl(274, 100%, 20%);
  --raw-color-violet-l30: hsl(276, 90%, 30%);
  --raw-color-violet-l40: hsl(278, 75%, 41%);
  --raw-color-violet-l50: hsl(280, 60%, 51%);
  --raw-color-violet-l60: hsl(280, 72%, 60%);
  --raw-color-violet-l70: hsl(280, 87%, 70%);
  --raw-color-violet-l80: hsl(278, 95%, 80%);
  --raw-color-violet-l90: hsl(274, 100%, 90%);
  --raw-color-violet-l94: hsl(272, 100%, 94%);
  --raw-color-violet-l97: hsl(272, 100%, 97%);
  --raw-color-violet-l100: hsl(270, 100%, 100%);
  --raw-color-indigo: var(--raw-color-indigo-l50);
  --raw-color-indigo-l0: hsl(250, 66%, 0%);
  --raw-color-indigo-l10: hsl(250, 66%, 10%);
  --raw-color-indigo-l20: hsl(250, 66%, 20%);
  --raw-color-indigo-l30: hsl(250, 66%, 30%);
  --raw-color-indigo-l40: hsl(250, 63%, 41%);
  --raw-color-indigo-l50: hsl(250, 56%, 51%);
  --raw-color-indigo-l60: hsl(250, 76%, 60%);
  --raw-color-indigo-l70: hsl(250, 75%, 70%);
  --raw-color-indigo-l80: hsl(250, 74%, 80%);
  --raw-color-indigo-l90: hsl(250, 67%, 90%);
  --raw-color-indigo-l94: hsl(250, 66%, 94%);
  --raw-color-indigo-l97: hsl(250, 66%, 97%);
  --raw-color-indigo-l100: hsl(250, 66%, 100%);
  --raw-color-blue: var(--raw-color-blue-l50);
  --raw-color-blue-l0: hsl(212, 85%, 0%);
  --raw-color-blue-l10: hsl(212, 85%, 10%);
  --raw-color-blue-l20: hsl(212, 95%, 20%);
  --raw-color-blue-l30: hsl(212, 95%, 30%);
  --raw-color-blue-l40: hsl(212, 85%, 41%);
  --raw-color-blue-l50: hsl(212, 85%, 51%);
  --raw-color-blue-l60: hsl(212, 85%, 60%);
  --raw-color-blue-l70: hsl(212, 85%, 70%);
  --raw-color-blue-l80: hsl(212, 85%, 80%);
  --raw-color-blue-l90: hsl(212, 85%, 90%);
  --raw-color-blue-l94: hsl(212, 85%, 94%);
  --raw-color-blue-l97: hsl(212, 85%, 97%);
  --raw-color-blue-l100: hsl(212, 85%, 100%);
  --raw-color-aqua: var(--raw-color-aqua-l50);
  --raw-color-aqua-l0: hsl(198, 84%, 0%);
  --raw-color-aqua-l10: hsl(198, 84%, 10%);
  --raw-color-aqua-l20: hsl(198, 84%, 20%);
  --raw-color-aqua-l30: hsl(198, 84%, 30%);
  --raw-color-aqua-l40: hsl(198, 90%, 41%);
  --raw-color-aqua-l50: hsl(198, 84%, 51%);
  --raw-color-aqua-l60: hsl(198, 84%, 60%);
  --raw-color-aqua-l70: hsl(198, 84%, 70%);
  --raw-color-aqua-l80: hsl(198, 84%, 80%);
  --raw-color-aqua-l90: hsl(198, 84%, 90%);
  --raw-color-aqua-l94: hsl(198, 84%, 93%);
  --raw-color-aqua-l97: hsl(198, 84%, 95%);
  --raw-color-aqua-l100: hsl(198, 84%, 100%);
  --raw-color-teal: var(--raw-color-teal-l40);
  --raw-color-teal-l0: hsl(165, 100%, 0%);
  --raw-color-teal-l10: hsl(165, 100%, 10%);
  --raw-color-teal-l20: hsl(162, 100%, 20%);
  --raw-color-teal-l30: hsl(161, 100%, 30%);
  --raw-color-teal-l40: hsl(161, 88%, 40%);
  --raw-color-teal-l50: hsl(160, 70%, 51%);
  --raw-color-teal-l60: hsl(160, 67%, 60%);
  --raw-color-teal-l70: hsl(160, 69%, 70%);
  --raw-color-teal-l80: hsl(160, 69%, 81%);
  --raw-color-teal-l90: hsl(161, 73%, 90%);
  --raw-color-teal-l94: hsl(161, 100%, 93%);
  --raw-color-teal-l97: hsl(161, 100%, 95%);
  --raw-color-teal-l100: hsl(160, 100%, 100%);
  --raw-color-green: var(--raw-color-green-l40);
  --raw-color-green-l0: hsl(120, 52%, 0%);
  --raw-color-green-l10: hsl(120, 52%, 10%);
  --raw-color-green-l20: hsl(120, 62%, 20%);
  --raw-color-green-l30: hsl(120, 80%, 30%);
  --raw-color-green-l40: hsl(120, 71%, 40%);
  --raw-color-green-l50: hsl(120, 52%, 51%);
  --raw-color-green-l60: hsl(120, 54%, 60%);
  --raw-color-green-l70: hsl(120, 64%, 70%);
  --raw-color-green-l80: hsl(120, 62%, 81%);
  --raw-color-green-l90: hsl(120, 70%, 88%);
  --raw-color-green-l94: hsl(120, 80%, 91%);
  --raw-color-green-l97: hsl(120, 80%, 97%);
  --raw-color-green-l100: hsl(120, 80%, 100%);
  --raw-color-lime: var(--raw-color-lime-l40);
  --raw-color-lime-l0: hsl(81, 79%, 0%);
  --raw-color-lime-l10: hsl(81, 79%, 10%);
  --raw-color-lime-l20: hsl(81, 79%, 20%);
  --raw-color-lime-l30: hsl(81, 79%, 30%);
  --raw-color-lime-l40: hsl(81, 79%, 40%);
  --raw-color-lime-l50: hsl(81, 79%, 51%);
  --raw-color-lime-l60: hsl(81, 79%, 60%);
  --raw-color-lime-l70: hsl(81, 79%, 70%);
  --raw-color-lime-l80: hsl(81, 79%, 81%);
  --raw-color-lime-l90: hsl(81, 79%, 90%);
  --raw-color-lime-l94: hsl(81, 79%, 93%);
  --raw-color-lime-l97: hsl(81, 79%, 97%);
  --raw-color-lime-l100: hsl(81, 79%, 100%);
  --raw-color-yellow: var(--raw-color-yellow-l50);
  --raw-color-yellow-l0: hsl(47, 100%, 0%);
  --raw-color-yellow-l10: hsl(47, 100%, 11%);
  --raw-color-yellow-l20: hsl(48, 100%, 21%);
  --raw-color-yellow-l30: hsl(50, 100%, 32%);
  --raw-color-yellow-l40: hsl(50, 100%, 42%);
  --raw-color-yellow-l50: hsl(57, 100%, 50%);
  --raw-color-yellow-l60: hsl(57, 100%, 63%);
  --raw-color-yellow-l70: hsl(59, 100%, 71%);
  --raw-color-yellow-l80: hsl(57, 100%, 80%);
  --raw-color-yellow-l90: hsl(63, 100%, 88%);
  --raw-color-yellow-l94: hsl(65, 100%, 92%);
  --raw-color-yellow-l97: hsl(65, 100%, 96%);
  --raw-color-yellow-l100: hsl(65, 100%, 100%);
  --raw-color-gold: var(--raw-color-gold-l50);
  --raw-color-gold-l0: hsl(25, 100%, 0%);
  --raw-color-gold-l10: hsl(25, 100%, 10%);
  --raw-color-gold-l20: hsl(25, 80%, 20%);
  --raw-color-gold-l30: hsl(35, 90%, 32%);
  --raw-color-gold-l40: hsl(40, 96%, 42%);
  --raw-color-gold-l50: hsl(43, 100%, 48%);
  --raw-color-gold-l60: hsl(43, 100%, 60%);
  --raw-color-gold-l70: hsl(43, 100%, 70%);
  --raw-color-gold-l80: hsl(44, 100%, 80%);
  --raw-color-gold-l90: hsl(45, 100%, 90%);
  --raw-color-gold-l94: hsl(46, 100%, 93%);
  --raw-color-gold-l97: hsl(46, 100%, 96%);
  --raw-color-gold-l100: hsl(46, 100%, 100%);
  --raw-color-orange: var(--raw-color-orange-l60);
  --raw-color-orange-l0: hsl(17, 100%, 0%);
  --raw-color-orange-l10: hsl(18, 100%, 10%);
  --raw-color-orange-l20: hsl(19, 100%, 20%);
  --raw-color-orange-l30: hsl(20, 100%, 30%);
  --raw-color-orange-l40: hsl(22, 100%, 40%);
  --raw-color-orange-l50: hsl(25, 91%, 50%);
  --raw-color-orange-l60: hsl(25, 100%, 60%);
  --raw-color-orange-l70: hsl(25, 100%, 70%);
  --raw-color-orange-l80: hsl(25, 100%, 80%);
  --raw-color-orange-l90: hsl(25, 100%, 90%);
  --raw-color-orange-l94: hsl(25, 100%, 93%);
  --raw-color-orange-l97: hsl(25, 100%, 97%);
  --raw-color-orange-l100: hsl(25, 100%, 100%);
  --raw-color-red: var(--raw-color-red-l50);
  --raw-color-red-l0: hsl(0, 60%, 0%);
  --raw-color-red-l10: hsl(0, 60%, 8%);
  --raw-color-red-l20: hsl(0, 65%, 20%);
  --raw-color-red-l30: hsl(0, 70%, 30%);
  --raw-color-red-l40: hsl(0, 75%, 41%);
  --raw-color-red-l50: hsl(0, 80%, 50%);
  --raw-color-red-l60: hsl(0, 85%, 60%);
  --raw-color-red-l70: hsl(0, 90%, 70%);
  --raw-color-red-l80: hsl(0, 92%, 83%);
  --raw-color-red-l90: hsl(0, 94%, 90%);
  --raw-color-red-l94: hsl(0, 95%, 94%);
  --raw-color-red-l97: hsl(0, 95%, 98%);
  --raw-color-red-l100: hsl(0, 99%, 100%);
  --raw-dimension-l0: 0rem;
  --raw-dimension-l12: 0.125rem;
  --raw-dimension-l25: 0.25rem;
  --raw-dimension-l50: 0.5rem;
  --raw-dimension-l75: 0.75rem;
  --raw-dimension-l100: 1rem;
  --raw-dimension-l150: 1.5rem;
  --raw-dimension-l200: 2rem;
  --raw-dimension-l250: 2.5rem;
  --raw-dimension-l300: 3rem;
  --raw-dimension-l400: 4rem;
  --raw-dimension-l500: 5rem;
  --raw-duration-l50: 150ms;
  --raw-duration-l75: 225ms;
  --raw-duration-l100: 300ms;
  --raw-duration-l150: 450ms;
  --raw-duration-l200: 600ms;
  --raw-elevation-box-shadow-raised-l0: none;
  --raw-elevation-box-shadow-raised-l10: 0px 1px 1px rgba(0, 0, 0, 0.025);
  --raw-elevation-box-shadow-raised-l10-strong: 0px 1px 1px rgba(0, 0, 0, 0.25);
  --raw-elevation-box-shadow-raised-l20: 0px 1px 2px rgba(0, 0, 0, 0.05);
  --raw-elevation-box-shadow-raised-l20-strong: 0px 1px 2px rgba(0, 0, 0, 0.5);
  --raw-elevation-box-shadow-raised-l30: 0px 1px 3px rgba(0, 0, 0, 0.075);
  --raw-elevation-box-shadow-raised-l30-strong: 0px 1px 3px rgba(0, 0, 0, 0.55);
  --raw-elevation-box-shadow-raised-l40: 0px 2px 4px rgba(0, 0, 0, 0.1);
  --raw-elevation-box-shadow-raised-l40-strong: 0px 2px 4px rgba(0, 0, 0, 0.6);
  --raw-elevation-box-shadow-raised-l50: 0px 2px 8px rgba(0, 0, 0, 0.125);
  --raw-elevation-box-shadow-raised-l50-strong: 0px 2px 8px rgba(0, 0, 0, 0.65);
  --raw-elevation-box-shadow-raised-l60: 0px 2px 10px rgba(0, 0, 0, 0.125);
  --raw-elevation-box-shadow-raised-l60-strong: 0px 2px 10px rgba(0, 0, 0, 0.65);
  --raw-elevation-box-shadow-raised-l70: 0px 3px 12px rgba(0, 0, 0, 0.15);
  --raw-elevation-box-shadow-raised-l70-strong: 0px 3px 12px rgba(0, 0, 0, 0.7);
  --raw-elevation-box-shadow-raised-l80: 0px 6px 14px rgba(0, 0, 0, 0.175);
  --raw-elevation-box-shadow-raised-l80-strong: 0px 6px 14px rgba(0, 0, 0, 0.75);
  --raw-elevation-box-shadow-raised-l90: 0px 7px 15px rgba(0, 0, 0, 0.175);
  --raw-elevation-box-shadow-raised-l90-strong: 0px 7px 15px rgba(0, 0, 0, 0.75);
  --raw-elevation-box-shadow-raised-l100: 0px 8px 16px rgba(0, 0, 0, 0.2);
  --raw-elevation-box-shadow-raised-l100-strong: 0px 8px 16px rgba(0, 0, 0, 0.8);
  --raw-elevation-box-shadow-inset-l0: none;
  --raw-elevation-box-shadow-inset-l10: inset 0px 1px 1px rgba(0, 0, 0, 0.025);
  --raw-elevation-box-shadow-inset-l10-strong: inset 0px 1px 1px rgba(0, 0, 0, 0.25);
  --raw-elevation-box-shadow-inset-l20: inset 0px 1px 2px rgba(0, 0, 0, 0.05);
  --raw-elevation-box-shadow-inset-l20-strong: inset 0px 1px 2px rgba(0, 0, 0, 0.5);
  --raw-elevation-box-shadow-inset-l30: inset 0px 1px 3px rgba(0, 0, 0, 0.075);
  --raw-elevation-box-shadow-inset-l30-strong: inset 0px 1px 3px rgba(0, 0, 0, 0.55);
  --raw-elevation-box-shadow-inset-l40: inset 0px 2px 4px rgba(0, 0, 0, 0.1);
  --raw-elevation-box-shadow-inset-l40-strong: inset 0px 2px 4px rgba(0, 0, 0, 0.6);
  --raw-elevation-box-shadow-inset-l50: inset 0px 2px 8px rgba(0, 0, 0, 0.125);
  --raw-elevation-box-shadow-inset-l50-strong: inset 0px 2px 8px rgba(0, 0, 0, 0.65);
  --raw-elevation-box-shadow-inset-l60: inset 0px 2px 10px rgba(0, 0, 0, 0.125);
  --raw-elevation-box-shadow-inset-l60-strong: inset 0px 2px 10px rgba(0, 0, 0, 0.65);
  --raw-elevation-box-shadow-inset-l70: inset 0px 3px 12px rgba(0, 0, 0, 0.15);
  --raw-elevation-box-shadow-inset-l70-strong: inset 0px 3px 12px rgba(0, 0, 0, 0.7);
  --raw-elevation-box-shadow-inset-l80: inset 0px 6px 14px rgba(0, 0, 0, 0.175);
  --raw-elevation-box-shadow-inset-l80-strong: inset 0px 6px 14px rgba(0, 0, 0, 0.75);
  --raw-elevation-box-shadow-inset-l90: inset 0px 7px 15px rgba(0, 0, 0, 0.175);
  --raw-elevation-box-shadow-inset-l90-strong: inset 0px 7px 15px rgba(0, 0, 0, 0.75);
  --raw-elevation-box-shadow-inset-l100: inset 0px 8px 16px rgba(0, 0, 0, 0.2);
  --raw-elevation-box-shadow-inset-l100-strong: inset 0px 8px 16px rgba(0, 0, 0, 0.8);
  --raw-glow-box-shadow-actioned: 0 0.1em 0.4em rgba(0, 157, 255, 0.5);
  --raw-glow-box-shadow-actioned-subtle: 0 0 0.4em rgba(0, 157, 255, 0.2);
  --raw-text-family-display: Avalon, sans-serif;
  --raw-text-family-heading: Avalon, sans-serif;
  --raw-text-family-body: Montserrat, sans-serif;
  --raw-text-family-label: Montserrat, sans-serif;
  --raw-text-size-l25: 0.25rem;
  --raw-text-size-l37: 0.375rem;
  --raw-text-size-l50: 0.50rem;
  --raw-text-size-l75: 0.75rem;
  --raw-text-size-l81: 0.8125rem;
  --raw-text-size-l92: 0.925rem;
  --raw-text-size-l100: 1rem;
  --raw-text-size-l125: 1.25rem;
  --raw-text-size-l150: 1.5rem;
  --raw-text-size-l200: 2rem;
  --raw-text-size-l300: 3rem;
  --raw-text-size-l400: 4rem;
  --raw-text-size-l600: 6rem;
  --raw-text-line-height-l100: 1.00;
  --raw-text-line-height-l125: 1.25;
  --raw-text-line-height-l150: 1.5;
  --raw-text-line-height-l175: 1.75;
  --raw-text-line-height-l200: 2.00;
  --raw-text-weight-l100: 100;
  --raw-text-weight-l200: 200;
  --raw-text-weight-l300: 300;
  --raw-text-weight-l400: 400;
  --raw-text-weight-l500: 500;
  --raw-text-weight-l600: 600;
  --raw-text-weight-l700: 700;
  --raw-text-weight-l800: 800;
  --raw-text-weight-l900: 900;
  --raw-text-weight-l950: 950;
  --raw-text-weight-thin: var(--raw-text-weight-l100);
  --raw-text-weight-extra-light: var(--raw-text-weight-l200);
  --raw-text-weight-light: var(--raw-text-weight-l300);
  --raw-text-weight-regular: var(--raw-text-weight-l400);
  --raw-text-weight-medium: var(--raw-text-weight-l500);
  --raw-text-weight-semi-bold: var(--raw-text-weight-l600);
  --raw-text-weight-bold: var(--raw-text-weight-l700);
  --raw-text-weight-extra-bold: var(--raw-text-weight-l800);
  --raw-text-weight-black: var(--raw-text-weight-l900);
  --raw-text-weight-extra-black: var(--raw-text-weight-l950);
  --raw-text-letter-spacing-normal: normal;
  --raw-text-letter-spacing-wide: 0.05em;
}