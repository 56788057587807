[data-theme="debug"] {
/* button */
  --button-color-background-primary: hsl(183, 70%, 55%);
  --button-color-background-primary-hover: hsl(183, 70%, 60%);
  --button-color-background-primary-active: hsl(183, 70%, 50%);
  --button-color-background-secondary: hsl(183, 70%, 80%);
  --button-color-background-secondary-dark-mode: hsl(183, 70%, 37.5%);
  --button-color-background-secondary-hover: hsl(183, 70%, 80%);
  --button-color-background-secondary-hover-dark-mode: hsl(183, 70%, 37.5%);
  --button-color-background-secondary-active: hsl(183, 70%, 80%);
  --button-color-background-secondary-active-dark-mode: hsl(183, 70%, 37.5%);
  --button-color-background-selected: hsl(183, 70%, 78.5%);
  --button-color-background-selected-dark-mode: hsl(183, 70%, 45%);
  --button-color-background-selected-hover: hsl(183, 70%, 78.5%);
  --button-color-background-selected-hover-dark-mode: hsl(183, 70%, 45%);
  --button-color-background-selected-active: hsl(183, 70%, 77%);
  --button-color-background-selected-active-dark-mode: hsl(183, 70%, 45%);
  --button-color-background-api: hsl(183,70%,57.58169934640523%);
  --button-color-background-api-hover: hsl(183,70%,65.16339869281046%);
  --button-color-background-api-active: hsl(183,70%,50.130718954248366%);
  --button-color-border-outer-primary: hsl(183, 70%, 55%);
  --button-color-border-outer-primary-hover: hsl(183, 70%, 60%);
  --button-color-border-outer-primary-active: hsl(183, 70%, 50%);
  --button-color-border-outer-secondary: hsl(183, 70%, 75%);
  --button-color-border-outer-secondary-dark-mode: hsl(183, 70%, 40%);
  --button-color-border-outer-secondary-hover: hsl(183, 70%, 65%);
  --button-color-border-outer-secondary-hover-dark-mode: hsl(183, 70%, 50.5%);
  --button-color-border-outer-secondary-active: hsl(183, 70%, 71.5%);
  --button-color-border-outer-secondary-active-dark-mode: hsl(183, 70%, 45%);
  --button-color-border-outer-selected: hsl(183, 70%, 75%);
  --button-color-border-outer-selected-hover: hsl(183, 70%, 65%);
  --button-color-border-outer-selected-active: hsl(183, 70%, 70%);
  --button-color-border-inner-secondary: hsl(183, 70%, 75%);
  --button-color-border-inner-secondary-dark-mode: hsl(183, 70%, 40%);
  --button-color-border-inner-secondary-hover: hsl(183, 70%, 65%);
  --button-color-border-inner-secondary-hover-dark-mode: hsl(183, 70%, 50.5%);
  --button-color-border-inner-secondary-active: hsl(183, 70%, 71.5%);
  --button-color-border-inner-secondary-active-dark-mode: hsl(183, 70%, 45%);
  --button-color-border-inner-selected: hsl(183, 70%, 70%);
  --button-color-border-inner-selected-dark-mode: hsl(183, 70%, 35%);
  --button-color-border-inner-selected-hover: hsl(183, 70%, 75%);
  --button-color-border-inner-selected-hover-dark-mode: hsl(183, 70%, 35%);
  --button-color-text-primary: hsl(183,70%,80%);
  --button-color-text-secondary: hsl(183,70%,30%);
  --button-color-text-secondary-dark-mode: hsl(183,70%,80%);
  --button-color-text-secondary-hover: hsl(183,70%,30%);
  --button-color-text-secondary-hover-dark-mode: hsl(183,70%,80%);
  --button-color-text-secondary-active: hsl(183,70%,30%);
  --button-color-text-secondary-active-dark-mode: hsl(183,70%,80%);
  --button-color-text-selected: hsl(183, 70%, 55.5%);
  --button-color-text-selected-dark-mode: hsl(183, 70%, 55.5%);
  --button-color-text-selected-hover: hsl(183, 70%, 55.5%);
  --button-color-text-selected-hover-dark-mode: hsl(183, 70%, 55.5%);
  --button-color-text-selected-active: hsl(183, 70%, 55.5%);
  --button-color-text-selected-active-dark-mode: hsl(183, 70%, 55.5%);
  --button-dimension-height: 2rem;
  --button-dimension-height-l200: 2rem;
  --button-dimension-padding-left: 2rem;
  --button-dimension-padding-left-l200: 2rem;
/* card */
  --card-color-background: hsl(183,70%,80%);
  --card-color-background-dark-mode: hsl(183, 70%, 37.5%);
  --card-color-text: hsl(183,70%,30%);
  --card-color-text-dark-mode: hsl(183,70%,80%);
  --card-color-border: hsl(183, 70%, 77%);
  --card-color-border-dark-mode: hsl(183, 70%, 32%);
  --card-color-border-internal: hsl(183, 70%, 77%);
  --card-color-border-internal-dark-mode: hsl(183, 70%, 32%);
  --card-box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.125);
  --card-border-radius: 1rem;
  --card-statistic-heading-text-family: Avalon, sans-serif;
  --card-statistic-heading-text-size: 0.8125rem;
/* chart */
  --chart-progress-bar-color-background: hsl(183, 70%, 75%);
  --chart-period-pill-color-background: hsl(183, 70%, 78.5%);
  --chart-period-pill-color-border: hsl(183, 70%, 75%);
/* chip */
  --chip-color-background-tag: hsl(183, 70%, 75%);
  --chip-color-background-tag-hover: hsl(183, 70%, 77%);
  --chip-color-background-assist: hsl(183, 70%, 75%);
  --chip-color-background-assist-hover: hsl(183, 70%, 77%);
  --chip-color-background-filter: hsl(183, 70%, 75%);
  --chip-color-background-filter-hover: hsl(183, 70%, 77%);
  --chip-color-background-input: hsl(183, 70%, 75%);
  --chip-color-background-input-hover: hsl(183, 70%, 77%);
  --chip-color-background-suggestion: hsl(183, 70%, 75%);
  --chip-color-background-suggestion-hover: hsl(183, 70%, 77%);
  --chip-color-text-tag: hsl(183, 70%, 40%);
  --chip-color-text-tag-hover: hsl(183, 70%, 45%);
  --chip-color-text-assist: hsl(183, 70%, 40%);
  --chip-color-text-assist-hover: hsl(183, 70%, 45%);
  --chip-color-text-filter: hsl(183, 70%, 40%);
  --chip-color-text-filter-hover: hsl(183, 70%, 45%);
  --chip-color-text-input: hsl(183, 70%, 40%);
  --chip-color-text-input-hover: hsl(183, 70%, 45%);
  --chip-color-text-suggestion: hsl(183, 70%, 40%);
  --chip-color-text-suggestion-hover: hsl(183, 70%, 45%);
/* form */
  --form-label-text-color: hsl(183, 70%, 34%);
  --form-field-color-background-subtle: hsl(183,70%,80%);
  --form-field-color-background-subtle-dark-mode: hsl(183, 70%, 40%);
  --form-field-color-background-subtle-on-impact: hsl(183, 70%, 40%);
  --form-field-color-background-subtle-on-impact-dark-mode: hsl(183, 70%, 40%);
  --form-field-color-border-subtle: hsl(183, 70%, 75%);
  --form-field-color-border-subtle-on-impact: hsl(183, 70%, 45%);
  --form-field-color-text-subtle: hsl(183,70%,80%);
  --form-field-color-text-subtle-on-impact: hsl(183, 70%, 70%);
  --form-field-placeholder-color-subtle: hsl(183, 70%, 65%);
  --form-field-placeholder-color-subtle-on-impact: hsl(183, 70%, 70%);
  --form-field-shortcut-color-background-on-impact: hsl(183, 70%, 45%);
  --form-field-shortcut-color-text-on-impact: hsl(183, 70%, 70%);
  --form-checkbox-color-text: hsl(183, 70%, 34%);
  --form-checkbox-color-text-selected: hsl(183,70%,80%);
  --form-checkbox-color-text-selected-dark-mode: hsl(183,70%,80%);
  --form-checkbox-color-background: hsl(183, 70%, 75%);
  --form-checkbox-color-background-hover: hsl(183, 70%, 71.5%);
  --form-checkbox-color-background-selected: hsl(183, 70%, 55.5%);
  --form-checkbox-color-background-selected-hover: hsl(183, 70%, 60%);
  --form-checkbox-color-background-disabled: hsl(183, 70%, 71.5%);
  --form-checkbox-color-background-disabled-hover: hsl(183, 70%, 71.5%);
  --form-checkbox-color-background-dark-mode: hsl(183, 70%, 34%);
  --form-checkbox-color-background-dark-mode-hover: hsl(183, 70%, 40%);
  --form-checkbox-color-background-dark-mode-selected: hsl(183, 70%, 55.5%);
  --form-checkbox-color-background-dark-mode-selected-hover: hsl(183, 70%, 60%);
/* header */
  --header-color-background: hsl(183, 70%, 45%);
  --header-color-background-dark-mode: hsl(183, 70%, 40%);
/* navigation */
  --navigation-step-bar-color-background: hsl(183, 70%, 78.5%);
/* tab */
  --tab-color-border-color: hsl(183, 70%, 78.5%);
  --tab-color-border-color-active: hsl(183, 70%, 60%);
/* table */
  --table-header-color-label: hsl(183, 70%, 60%);
  --table-color-background: hsl(183, 70%, 80%);
  --table-color-background-selected: hsl(183, 70%, 78.5%);
  --table-color-background-selected-on-dark: hsl(183, 70%, 78.5%);
  --table-color-background-hover: hsl(183, 70%, 78.5%);
  --table-color-border: hsl(183, 70%, 75%);
  --table-color-text: rgb(100, 203, 50);
  --table-text-family-header-label: Avalon, sans-serif;
  --table-text-weight-header-label: 700;
  --table-border-radius: 0.5rem;
  --table-dimension-padding-combined: 0.5rem 0.75rem 0.5rem 0.75rem;
  --table-dimension-padding-left: 0.75rem;
  --table-dimension-padding-right: 0.75rem;
  --table-dimension-padding-top: 0.5rem;
  --table-dimension-padding-bottom: 0.5rem;
/* tooltip */
  --tooltip-color-background-subtle: hsl(183,70%,80%);
  --tooltip-color-background-strong: hsl(183, 70%, 50.5%);
  --tooltip-color-background-impact: hsl(183,70%,36.666666666666664%);
/* raw */
  --raw-border-radius-none: 0;
  --raw-border-radius-l25: 0.25rem;
  --raw-border-radius-l50: 0.5rem;
  --raw-border-radius-l100: 1rem;
  --raw-border-radius-l150: 1.5rem;
  --raw-border-radius-l200: 2rem;
  --raw-border-radius-l300: 3rem;
  --raw-border-radius-l400: 4rem;
  --raw-border-radius-full: 100rem;
  --raw-color-white: hsl(183,70%,80%);
  --raw-color-black: hsl(183,70%,30%);
  --raw-color-grey: hsl(183, 70%, 55%);
  --raw-color-grey-l0: hsl(183, 70%, 30%);
  --raw-color-grey-l10: hsl(183, 70%, 34%);
  --raw-color-grey-l20: hsl(183, 70%, 40%);
  --raw-color-grey-l30: hsl(183, 70%, 45%);
  --raw-color-grey-l40: hsl(183, 70%, 50.5%);
  --raw-color-grey-l50: hsl(183, 70%, 55%);
  --raw-color-grey-l60: hsl(183, 70%, 60%);
  --raw-color-grey-l70: hsl(183, 70%, 65%);
  --raw-color-grey-l80: hsl(183, 70%, 71.5%);
  --raw-color-grey-l90: hsl(183, 70%, 75%);
  --raw-color-grey-l94: hsl(183, 70%, 77%);
  --raw-color-grey-l97: hsl(183, 70%, 78.5%);
  --raw-color-grey-l100: hsl(183, 70%, 80%);
  --raw-color-neutral: hsl(183, 70%, 55%);
  --raw-color-neutral-l0: hsl(183, 70%, 30%);
  --raw-color-neutral-l5: hsl(183, 70%, 32%);
  --raw-color-neutral-l10: hsl(183, 70%, 34%);
  --raw-color-neutral-l15: hsl(183, 70%, 37.5%);
  --raw-color-neutral-l20: hsl(183, 70%, 40%);
  --raw-color-neutral-l30: hsl(183, 70%, 45%);
  --raw-color-neutral-l40: hsl(183, 70%, 50.5%);
  --raw-color-neutral-l50: hsl(183, 70%, 55%);
  --raw-color-neutral-l60: hsl(183, 70%, 60%);
  --raw-color-neutral-l70: hsl(183, 70%, 65%);
  --raw-color-neutral-l80: hsl(183, 70%, 71.5%);
  --raw-color-neutral-l90: hsl(183, 70%, 75%);
  --raw-color-neutral-l94: hsl(183, 70%, 77%);
  --raw-color-neutral-l97: hsl(183, 70%, 78.5%);
  --raw-color-neutral-l100: hsl(183, 70%, 80%);
  --raw-color-rose: hsl(183, 70%, 55%);
  --raw-color-rose-l0: hsl(183, 70%, 30%);
  --raw-color-rose-l10: hsl(183, 70%, 35%);
  --raw-color-rose-l20: hsl(183, 70%, 40%);
  --raw-color-rose-l30: hsl(183, 70%, 45%);
  --raw-color-rose-l40: hsl(183, 70%, 50%);
  --raw-color-rose-l50: hsl(183, 70%, 55%);
  --raw-color-rose-l60: hsl(183, 70%, 60%);
  --raw-color-rose-l70: hsl(183, 70%, 65%);
  --raw-color-rose-l80: hsl(183, 70%, 70%);
  --raw-color-rose-l90: hsl(183, 70%, 75%);
  --raw-color-rose-l94: hsl(183, 70%, 77%);
  --raw-color-rose-l97: hsl(183, 70%, 78.5%);
  --raw-color-rose-l100: hsl(183, 70%, 80%);
  --raw-color-magenta: hsl(183, 70%, 55%);
  --raw-color-magenta-l0: hsl(183, 70%, 30%);
  --raw-color-magenta-l10: hsl(183, 70%, 35%);
  --raw-color-magenta-l20: hsl(183, 70%, 40%);
  --raw-color-magenta-l30: hsl(183, 70%, 45%);
  --raw-color-magenta-l40: hsl(183, 70%, 50%);
  --raw-color-magenta-l50: hsl(183, 70%, 55%);
  --raw-color-magenta-l60: hsl(183, 70%, 60%);
  --raw-color-magenta-l70: hsl(183, 70%, 65%);
  --raw-color-magenta-l80: hsl(183, 70%, 70%);
  --raw-color-magenta-l90: hsl(183, 70%, 75%);
  --raw-color-magenta-l94: hsl(183, 70%, 77%);
  --raw-color-magenta-l97: hsl(183, 70%, 78.5%);
  --raw-color-magenta-l100: hsl(183, 70%, 80%);
  --raw-color-violet: hsl(183, 70%, 55.5%);
  --raw-color-violet-l0: hsl(183, 70%, 30%);
  --raw-color-violet-l10: hsl(183, 70%, 35%);
  --raw-color-violet-l20: hsl(183, 70%, 40%);
  --raw-color-violet-l30: hsl(183, 70%, 45%);
  --raw-color-violet-l40: hsl(183, 70%, 50.5%);
  --raw-color-violet-l50: hsl(183, 70%, 55.5%);
  --raw-color-violet-l60: hsl(183, 70%, 60%);
  --raw-color-violet-l70: hsl(183, 70%, 65%);
  --raw-color-violet-l80: hsl(183, 70%, 70%);
  --raw-color-violet-l90: hsl(183, 70%, 75%);
  --raw-color-violet-l94: hsl(183, 70%, 77%);
  --raw-color-violet-l97: hsl(183, 70%, 78.5%);
  --raw-color-violet-l100: hsl(183, 70%, 80%);
  --raw-color-indigo: hsl(183, 70%, 55.5%);
  --raw-color-indigo-l0: hsl(183, 70%, 30%);
  --raw-color-indigo-l10: hsl(183, 70%, 35%);
  --raw-color-indigo-l20: hsl(183, 70%, 40%);
  --raw-color-indigo-l30: hsl(183, 70%, 45%);
  --raw-color-indigo-l40: hsl(183, 70%, 50.5%);
  --raw-color-indigo-l50: hsl(183, 70%, 55.5%);
  --raw-color-indigo-l60: hsl(183, 70%, 60%);
  --raw-color-indigo-l70: hsl(183, 70%, 65%);
  --raw-color-indigo-l80: hsl(183, 70%, 70%);
  --raw-color-indigo-l90: hsl(183, 70%, 75%);
  --raw-color-indigo-l94: hsl(183, 70%, 77%);
  --raw-color-indigo-l97: hsl(183, 70%, 78.5%);
  --raw-color-indigo-l100: hsl(183, 70%, 80%);
  --raw-color-blue: hsl(183, 70%, 55.5%);
  --raw-color-blue-l0: hsl(183, 70%, 30%);
  --raw-color-blue-l10: hsl(183, 70%, 35%);
  --raw-color-blue-l20: hsl(183, 70%, 40%);
  --raw-color-blue-l30: hsl(183, 70%, 45%);
  --raw-color-blue-l40: hsl(183, 70%, 50.5%);
  --raw-color-blue-l50: hsl(183, 70%, 55.5%);
  --raw-color-blue-l60: hsl(183, 70%, 60%);
  --raw-color-blue-l70: hsl(183, 70%, 65%);
  --raw-color-blue-l80: hsl(183, 70%, 70%);
  --raw-color-blue-l90: hsl(183, 70%, 75%);
  --raw-color-blue-l94: hsl(183, 70%, 77%);
  --raw-color-blue-l97: hsl(183, 70%, 78.5%);
  --raw-color-blue-l100: hsl(183, 70%, 80%);
  --raw-color-aqua: hsl(183, 70%, 55.5%);
  --raw-color-aqua-l0: hsl(183, 70%, 30%);
  --raw-color-aqua-l10: hsl(183, 70%, 35%);
  --raw-color-aqua-l20: hsl(183, 70%, 40%);
  --raw-color-aqua-l30: hsl(183, 70%, 45%);
  --raw-color-aqua-l40: hsl(183, 70%, 50.5%);
  --raw-color-aqua-l50: hsl(183, 70%, 55.5%);
  --raw-color-aqua-l60: hsl(183, 70%, 60%);
  --raw-color-aqua-l70: hsl(183, 70%, 65%);
  --raw-color-aqua-l80: hsl(183, 70%, 70%);
  --raw-color-aqua-l90: hsl(183, 70%, 75%);
  --raw-color-aqua-l94: hsl(183, 70%, 76.5%);
  --raw-color-aqua-l97: hsl(183, 70%, 77.5%);
  --raw-color-aqua-l100: hsl(183, 70%, 80%);
  --raw-color-teal: hsl(183, 70%, 50%);
  --raw-color-teal-l0: hsl(183, 70%, 30%);
  --raw-color-teal-l10: hsl(183, 70%, 35%);
  --raw-color-teal-l20: hsl(183, 70%, 40%);
  --raw-color-teal-l30: hsl(183, 70%, 45%);
  --raw-color-teal-l40: hsl(183, 70%, 50%);
  --raw-color-teal-l50: hsl(183, 70%, 55.5%);
  --raw-color-teal-l60: hsl(183, 70%, 60%);
  --raw-color-teal-l70: hsl(183, 70%, 65%);
  --raw-color-teal-l80: hsl(183, 70%, 70.5%);
  --raw-color-teal-l90: hsl(183, 70%, 75%);
  --raw-color-teal-l94: hsl(183, 70%, 76.5%);
  --raw-color-teal-l97: hsl(183, 70%, 77.5%);
  --raw-color-teal-l100: hsl(183, 70%, 80%);
  --raw-color-green: hsl(183, 70%, 50%);
  --raw-color-green-l0: hsl(183, 70%, 30%);
  --raw-color-green-l10: hsl(183, 70%, 35%);
  --raw-color-green-l20: hsl(183, 70%, 40%);
  --raw-color-green-l30: hsl(183, 70%, 45%);
  --raw-color-green-l40: hsl(183, 70%, 50%);
  --raw-color-green-l50: hsl(183, 70%, 55.5%);
  --raw-color-green-l60: hsl(183, 70%, 60%);
  --raw-color-green-l70: hsl(183, 70%, 65%);
  --raw-color-green-l80: hsl(183, 70%, 70.5%);
  --raw-color-green-l90: hsl(183, 70%, 74%);
  --raw-color-green-l94: hsl(183, 70%, 75.5%);
  --raw-color-green-l97: hsl(183, 70%, 78.5%);
  --raw-color-green-l100: hsl(183, 70%, 80%);
  --raw-color-lime: hsl(183, 70%, 50%);
  --raw-color-lime-l0: hsl(183, 70%, 30%);
  --raw-color-lime-l10: hsl(183, 70%, 35%);
  --raw-color-lime-l20: hsl(183, 70%, 40%);
  --raw-color-lime-l30: hsl(183, 70%, 45%);
  --raw-color-lime-l40: hsl(183, 70%, 50%);
  --raw-color-lime-l50: hsl(183, 70%, 55.5%);
  --raw-color-lime-l60: hsl(183, 70%, 60%);
  --raw-color-lime-l70: hsl(183, 70%, 65%);
  --raw-color-lime-l80: hsl(183, 70%, 70.5%);
  --raw-color-lime-l90: hsl(183, 70%, 75%);
  --raw-color-lime-l94: hsl(183, 70%, 76.5%);
  --raw-color-lime-l97: hsl(183, 70%, 78.5%);
  --raw-color-lime-l100: hsl(183, 70%, 80%);
  --raw-color-yellow: hsl(183, 70%, 55%);
  --raw-color-yellow-l0: hsl(183, 70%, 30%);
  --raw-color-yellow-l10: hsl(183, 70%, 35.5%);
  --raw-color-yellow-l20: hsl(183, 70%, 40.5%);
  --raw-color-yellow-l30: hsl(183, 70%, 46%);
  --raw-color-yellow-l40: hsl(183, 70%, 51%);
  --raw-color-yellow-l50: hsl(183, 70%, 55%);
  --raw-color-yellow-l60: hsl(183, 70%, 61.5%);
  --raw-color-yellow-l70: hsl(183, 70%, 65.5%);
  --raw-color-yellow-l80: hsl(183, 70%, 70%);
  --raw-color-yellow-l90: hsl(183, 70%, 74%);
  --raw-color-yellow-l94: hsl(183, 70%, 76%);
  --raw-color-yellow-l97: hsl(183, 70%, 78%);
  --raw-color-yellow-l100: hsl(183, 70%, 80%);
  --raw-color-gold: hsl(183, 70%, 54%);
  --raw-color-gold-l0: hsl(183, 70%, 30%);
  --raw-color-gold-l10: hsl(183, 70%, 35%);
  --raw-color-gold-l20: hsl(183, 70%, 40%);
  --raw-color-gold-l30: hsl(183, 70%, 46%);
  --raw-color-gold-l40: hsl(183, 70%, 51%);
  --raw-color-gold-l50: hsl(183, 70%, 54%);
  --raw-color-gold-l60: hsl(183, 70%, 60%);
  --raw-color-gold-l70: hsl(183, 70%, 65%);
  --raw-color-gold-l80: hsl(183, 70%, 70%);
  --raw-color-gold-l90: hsl(183, 70%, 75%);
  --raw-color-gold-l94: hsl(183, 70%, 76.5%);
  --raw-color-gold-l97: hsl(183, 70%, 78%);
  --raw-color-gold-l100: hsl(183, 70%, 80%);
  --raw-color-orange: hsl(183, 70%, 60%);
  --raw-color-orange-l0: hsl(183, 70%, 30%);
  --raw-color-orange-l10: hsl(183, 70%, 35%);
  --raw-color-orange-l20: hsl(183, 70%, 40%);
  --raw-color-orange-l30: hsl(183, 70%, 45%);
  --raw-color-orange-l40: hsl(183, 70%, 50%);
  --raw-color-orange-l50: hsl(183, 70%, 55%);
  --raw-color-orange-l60: hsl(183, 70%, 60%);
  --raw-color-orange-l70: hsl(183, 70%, 65%);
  --raw-color-orange-l80: hsl(183, 70%, 70%);
  --raw-color-orange-l90: hsl(183, 70%, 75%);
  --raw-color-orange-l94: hsl(183, 70%, 76.5%);
  --raw-color-orange-l97: hsl(183, 70%, 78.5%);
  --raw-color-orange-l100: hsl(183, 70%, 80%);
  --raw-color-red: hsl(183, 70%, 55%);
  --raw-color-red-l0: hsl(183, 70%, 30%);
  --raw-color-red-l10: hsl(183, 70%, 34%);
  --raw-color-red-l20: hsl(183, 70%, 40%);
  --raw-color-red-l30: hsl(183, 70%, 45%);
  --raw-color-red-l40: hsl(183, 70%, 50.5%);
  --raw-color-red-l50: hsl(183, 70%, 55%);
  --raw-color-red-l60: hsl(183, 70%, 60%);
  --raw-color-red-l70: hsl(183, 70%, 65%);
  --raw-color-red-l80: hsl(183, 70%, 71.5%);
  --raw-color-red-l90: hsl(183, 70%, 75%);
  --raw-color-red-l94: hsl(183, 70%, 77%);
  --raw-color-red-l97: hsl(183, 70%, 79%);
  --raw-color-red-l100: hsl(183, 70%, 80%);
  --raw-dimension-l0: 0rem;
  --raw-dimension-l12: 0.125rem;
  --raw-dimension-l25: 0.25rem;
  --raw-dimension-l50: 0.5rem;
  --raw-dimension-l75: 0.75rem;
  --raw-dimension-l100: 1rem;
  --raw-dimension-l150: 1.5rem;
  --raw-dimension-l200: 2rem;
  --raw-dimension-l250: 2.5rem;
  --raw-dimension-l300: 3rem;
  --raw-dimension-l400: 4rem;
  --raw-dimension-l500: 5rem;
  --raw-duration-l50: 150ms;
  --raw-duration-l75: 225ms;
  --raw-duration-l100: 300ms;
  --raw-duration-l150: 450ms;
  --raw-duration-l200: 600ms;
  --raw-elevation-box-shadow-raised-l0: none;
  --raw-elevation-box-shadow-raised-l10: 0px 1px 1px rgba(0, 0, 0, 0.025);
  --raw-elevation-box-shadow-raised-l10-strong: 0px 1px 1px rgba(0, 0, 0, 0.25);
  --raw-elevation-box-shadow-raised-l20: 0px 1px 2px rgba(0, 0, 0, 0.05);
  --raw-elevation-box-shadow-raised-l20-strong: 0px 1px 2px rgba(0, 0, 0, 0.5);
  --raw-elevation-box-shadow-raised-l30: 0px 1px 3px rgba(0, 0, 0, 0.075);
  --raw-elevation-box-shadow-raised-l30-strong: 0px 1px 3px rgba(0, 0, 0, 0.55);
  --raw-elevation-box-shadow-raised-l40: 0px 2px 4px rgba(0, 0, 0, 0.1);
  --raw-elevation-box-shadow-raised-l40-strong: 0px 2px 4px rgba(0, 0, 0, 0.6);
  --raw-elevation-box-shadow-raised-l50: 0px 2px 8px rgba(0, 0, 0, 0.125);
  --raw-elevation-box-shadow-raised-l50-strong: 0px 2px 8px rgba(0, 0, 0, 0.65);
  --raw-elevation-box-shadow-raised-l60: 0px 2px 10px rgba(0, 0, 0, 0.125);
  --raw-elevation-box-shadow-raised-l60-strong: 0px 2px 10px rgba(0, 0, 0, 0.65);
  --raw-elevation-box-shadow-raised-l70: 0px 3px 12px rgba(0, 0, 0, 0.15);
  --raw-elevation-box-shadow-raised-l70-strong: 0px 3px 12px rgba(0, 0, 0, 0.7);
  --raw-elevation-box-shadow-raised-l80: 0px 6px 14px rgba(0, 0, 0, 0.175);
  --raw-elevation-box-shadow-raised-l80-strong: 0px 6px 14px rgba(0, 0, 0, 0.75);
  --raw-elevation-box-shadow-raised-l90: 0px 7px 15px rgba(0, 0, 0, 0.175);
  --raw-elevation-box-shadow-raised-l90-strong: 0px 7px 15px rgba(0, 0, 0, 0.75);
  --raw-elevation-box-shadow-raised-l100: 0px 8px 16px rgba(0, 0, 0, 0.2);
  --raw-elevation-box-shadow-raised-l100-strong: 0px 8px 16px rgba(0, 0, 0, 0.8);
  --raw-elevation-box-shadow-inset-l0: none;
  --raw-elevation-box-shadow-inset-l10: inset 0px 1px 1px rgba(0, 0, 0, 0.025);
  --raw-elevation-box-shadow-inset-l10-strong: inset 0px 1px 1px rgba(0, 0, 0, 0.25);
  --raw-elevation-box-shadow-inset-l20: inset 0px 1px 2px rgba(0, 0, 0, 0.05);
  --raw-elevation-box-shadow-inset-l20-strong: inset 0px 1px 2px rgba(0, 0, 0, 0.5);
  --raw-elevation-box-shadow-inset-l30: inset 0px 1px 3px rgba(0, 0, 0, 0.075);
  --raw-elevation-box-shadow-inset-l30-strong: inset 0px 1px 3px rgba(0, 0, 0, 0.55);
  --raw-elevation-box-shadow-inset-l40: inset 0px 2px 4px rgba(0, 0, 0, 0.1);
  --raw-elevation-box-shadow-inset-l40-strong: inset 0px 2px 4px rgba(0, 0, 0, 0.6);
  --raw-elevation-box-shadow-inset-l50: inset 0px 2px 8px rgba(0, 0, 0, 0.125);
  --raw-elevation-box-shadow-inset-l50-strong: inset 0px 2px 8px rgba(0, 0, 0, 0.65);
  --raw-elevation-box-shadow-inset-l60: inset 0px 2px 10px rgba(0, 0, 0, 0.125);
  --raw-elevation-box-shadow-inset-l60-strong: inset 0px 2px 10px rgba(0, 0, 0, 0.65);
  --raw-elevation-box-shadow-inset-l70: inset 0px 3px 12px rgba(0, 0, 0, 0.15);
  --raw-elevation-box-shadow-inset-l70-strong: inset 0px 3px 12px rgba(0, 0, 0, 0.7);
  --raw-elevation-box-shadow-inset-l80: inset 0px 6px 14px rgba(0, 0, 0, 0.175);
  --raw-elevation-box-shadow-inset-l80-strong: inset 0px 6px 14px rgba(0, 0, 0, 0.75);
  --raw-elevation-box-shadow-inset-l90: inset 0px 7px 15px rgba(0, 0, 0, 0.175);
  --raw-elevation-box-shadow-inset-l90-strong: inset 0px 7px 15px rgba(0, 0, 0, 0.75);
  --raw-elevation-box-shadow-inset-l100: inset 0px 8px 16px rgba(0, 0, 0, 0.2);
  --raw-elevation-box-shadow-inset-l100-strong: inset 0px 8px 16px rgba(0, 0, 0, 0.8);
  --raw-glow-box-shadow-actioned: 0 0.1em 0.4em rgba(0, 157, 255, 0.5);
  --raw-glow-box-shadow-actioned-subtle: 0 0 0.4em rgba(0, 157, 255, 0.2);
  --raw-text-family-display: Avalon, sans-serif;
  --raw-text-family-heading: Avalon, sans-serif;
  --raw-text-family-body: Montserrat, sans-serif;
  --raw-text-family-label: Montserrat, sans-serif;
  --raw-text-size-l25: 0.25rem;
  --raw-text-size-l37: 0.375rem;
  --raw-text-size-l50: 0.50rem;
  --raw-text-size-l75: 0.75rem;
  --raw-text-size-l81: 0.8125rem;
  --raw-text-size-l92: 0.925rem;
  --raw-text-size-l100: 1rem;
  --raw-text-size-l125: 1.25rem;
  --raw-text-size-l150: 1.5rem;
  --raw-text-size-l200: 2rem;
  --raw-text-size-l300: 3rem;
  --raw-text-size-l400: 4rem;
  --raw-text-size-l600: 6rem;
  --raw-text-line-height-l100: 1.00;
  --raw-text-line-height-l125: 1.25;
  --raw-text-line-height-l150: 1.5;
  --raw-text-line-height-l175: 1.75;
  --raw-text-line-height-l200: 2.00;
  --raw-text-weight-l100: 100;
  --raw-text-weight-l200: 200;
  --raw-text-weight-l300: 300;
  --raw-text-weight-l400: 400;
  --raw-text-weight-l500: 500;
  --raw-text-weight-l600: 600;
  --raw-text-weight-l700: 700;
  --raw-text-weight-l800: 800;
  --raw-text-weight-l900: 900;
  --raw-text-weight-l950: 950;
  --raw-text-weight-thin: 100;
  --raw-text-weight-extra-light: 200;
  --raw-text-weight-light: 300;
  --raw-text-weight-regular: 400;
  --raw-text-weight-medium: 500;
  --raw-text-weight-semi-bold: 600;
  --raw-text-weight-bold: 700;
  --raw-text-weight-extra-bold: 800;
  --raw-text-weight-black: 900;
  --raw-text-weight-extra-black: 950;
  --raw-text-letter-spacing-normal: normal;
  --raw-text-letter-spacing-wide: 0.05em;
/* border */
  --border-radius-none: 0;
  --border-radius-small: 0.5rem;
  --border-radius-medium: 1rem;
  --border-radius-large: 2rem;
  --border-radius-full: 100rem;
/* color */
  --color-text-base: hsl(183, 70%, 30%);
  --color-text-base-dark-mode: hsl(183, 70%, 71.5%);
  --color-text-base-subtle: hsl(183, 70%, 45%);
  --color-text-base-subtle-dark-mode: hsl(183, 70%, 60%);
  --color-text-display: hsl(183, 70%, 30%);
  --color-text-display-vibrant-gradient: rgb(100, 149, 50);
  --color-text-heading: hsl(183, 70%, 30%);
  --color-text-body: hsl(183, 70%, 30%);
  --color-text-subtle: hsl(183, 70%, 50.5%);
  --color-text-subtle-on-dark: hsl(183, 70%, 75%);
  --color-text-disclaimer: hsl(183, 70%, 50.5%);
  --color-border: hsl(183, 70%, 75%);
  --color-border-dark-mode: hsl(183, 70%, 45%);
  --color-background: hsl(183, 70%, 80%);
  --color-background-dark-mode: hsl(183, 70%, 34%);
  --color-background-alternate: hsl(183, 70%, 78.5%);
  --color-background-selected-subtle: hsl(183, 70%, 78.5%);
  --color-background-selected-strong: hsl(183, 70%, 55.5%);
  --color-background-positive: hsl(183, 70%, 77.5%);
  --color-background-negative: hsl(183, 70%, 79%);
  --color-background-impact: hsl(183, 70%, 45%);
  --color-background-impact-dark-mode: hsl(183, 70%, 32%);
  --color-background-impact-gradient: rgb(100, 228, 50);
  --color-background-impact-alternate: hsl(183, 70%, 50.5%);
  --color-background-impact-alternate-dark-mode: hsl(183, 70%, 34%);
  --color-background-comment: hsl(183, 70%, 77%);
  --color-primary-shade: hsl(183, 70%, 55%);
  --color-primary: hsl(183, 70%, 60%);
  --color-primary-tint: hsl(183, 70%, 65%);
  --color-primary-subtle: hsl(183, 70%, 78.5%);
  --color-primary-subtle-dark-mode: hsl(183, 70%, 40%);
  --color-primary-subtle-on-subtle: hsl(183, 70%, 75%);
  --color-primary-subtle-on-subtle-dark-mode: hsl(183, 70%, 45%);
  --color-secondary-shade: hsl(183, 70%, 50.5%);
  --color-secondary: hsl(183, 70%, 55.5%);
  --color-secondary-tint: hsl(183, 70%, 60%);
  --color-secondary-subtle: hsl(183, 70%, 78.5%);
  --color-secondary-subtle-dark-mode: hsl(183, 70%, 40%);
  --color-neutral-shade: hsl(183, 70%, 50.5%);
  --color-neutral: hsl(183, 70%, 55%);
  --color-neutral-tint: hsl(183, 70%, 60%);
  --color-neutral-subtle: hsl(183, 70%, 78.5%);
  --color-neutral-subtle-dark-mode: hsl(183, 70%, 40%);
  --color-accent-shade: hsl(183, 70%, 51%);
  --color-accent: hsl(183, 70%, 55%);
  --color-accent-tint: hsl(183, 70%, 61.5%);
  --color-accent-subtle: hsl(183, 70%, 78%);
  --color-accent-subtle-dark-mode: hsl(183, 70%, 40.5%);
  --color-actioned-shade: hsl(183, 70%, 50%);
  --color-actioned: hsl(183, 70%, 55%);
  --color-actioned-tint: hsl(183, 70%, 60%);
  --color-actioned-subtle: hsl(183, 70%, 78.5%);
  --color-actioned-subtle-dark-mode: hsl(183, 70%, 40%);
  --color-selected-shade: hsl(183, 70%, 50.5%);
  --color-selected: hsl(183, 70%, 55.5%);
  --color-selected-tint: hsl(183, 70%, 60%);
  --color-selected-subtle: hsl(183, 70%, 78.5%);
  --color-selected-subtle-dark-mode: hsl(183, 70%, 40%);
  --color-feedback-positive-shade: hsl(183, 70%, 50%);
  --color-feedback-positive: hsl(183, 70%, 55.5%);
  --color-feedback-positive-tint: hsl(183, 70%, 60%);
  --color-feedback-positive-subtle: hsl(183, 70%, 77.5%);
  --color-feedback-positive-subtle-dark-mode: hsl(183, 70%, 40%);
  --color-feedback-warning-shade: hsl(183, 70%, 50%);
  --color-feedback-warning: hsl(183, 70%, 55%);
  --color-feedback-warning-tint: hsl(183, 70%, 60%);
  --color-feedback-warning-subtle: hsl(183, 70%, 78.5%);
  --color-feedback-warning-subtle-dark-mode: hsl(183, 70%, 40%);
  --color-feedback-negative-shade: hsl(183, 70%, 50.5%);
  --color-feedback-negative: hsl(183, 70%, 55%);
  --color-feedback-negative-tint: hsl(183, 70%, 71.5%);
  --color-feedback-negative-subtle: hsl(183, 70%, 79%);
  --color-feedback-negative-subtle-dark-mode: hsl(183, 70%, 40%);
  --color-feedback-experimental: hsl(183, 70%, 55%);
  --color-data-sequential-primary-to-faded-step1: hsl(183,70%,53.39869281045752%);
  --color-data-sequential-primary-to-faded-step2: hsl(183,70%,57.908496732026144%);
  --color-data-sequential-primary-to-faded-step3: hsl(183,70%,59.47712418300654%);
  --color-data-sequential-primary-to-faded-step4: hsl(183,70%,60.98039215686275%);
  --color-data-sequential-primary-to-faded-step5: hsl(183,70%,62.549019607843135%);
  --color-data-sequential-primary-to-faded-step6: hsl(183,70%,64.05228758169935%);
  --color-data-sequential-primary-to-faded-step7: hsl(183,70%,65.62091503267973%);
  --color-data-sequential-primary-to-faded-step8: hsl(183,70%,67.12418300653596%);
  --color-data-sequential-primary-to-faded-step9: hsl(183,70%,68.69281045751634%);
  --color-data-sequential-primary-to-faded-step10: hsl(183,70%,70.13071895424837%);
  --color-data-sequential-primary-to-purple-faded-step1: hsl(183,70%,53.39869281045752%);
  --color-data-sequential-primary-to-purple-faded-step2: hsl(183,70%,58.692810457516345%);
  --color-data-sequential-primary-to-purple-faded-step3: hsl(183,70%,60.98039215686275%);
  --color-data-sequential-primary-to-purple-faded-step4: hsl(183,70%,63.33333333333333%);
  --color-data-sequential-primary-to-purple-faded-step5: hsl(183,70%,65.55555555555556%);
  --color-data-sequential-primary-to-purple-faded-step6: hsl(183,70%,67.97385620915033%);
  --color-data-sequential-primary-to-purple-faded-step7: hsl(183,70%,70.19607843137254%);
  --color-data-sequential-primary-to-purple-faded-step8: hsl(183,70%,72.54901960784314%);
  --color-data-sequential-primary-to-purple-faded-step9: hsl(183,70%,74.83660130718954%);
  --color-data-sequential-primary-to-purple-faded-step10: hsl(183,70%,77.05882352941177%);
  --color-data-sequential-primary-to-purple-step1: hsl(183,70%,53.39869281045752%);
  --color-data-sequential-primary-to-purple-step2: hsl(183,70%,54.90196078431373%);
  --color-data-sequential-primary-to-purple-step3: hsl(183,70%,56.209150326797385%);
  --color-data-sequential-primary-to-purple-step4: hsl(183,70%,57.77777777777777%);
  --color-data-sequential-primary-to-purple-step5: hsl(183,70%,59.15032679738562%);
  --color-data-sequential-primary-to-purple-step6: hsl(183,70%,60.71895424836601%);
  --color-data-sequential-primary-to-purple-step7: hsl(183,70%,62.091503267973856%);
  --color-data-sequential-primary-to-purple-step8: hsl(183,70%,63.66013071895424%);
  --color-data-sequential-primary-to-purple-step9: hsl(183,70%,64.96732026143792%);
  --color-data-sequential-primary-to-purple-step10: hsl(183,70%,66.47058823529412%);
  --color-data-sequential-positive-to-negative-step1: hsl(183,70%,57.450980392156865%);
  --color-data-sequential-positive-to-negative-step2: hsl(183,70%,57.385620915032675%);
  --color-data-sequential-positive-to-negative-step3: hsl(183,70%,57.450980392156865%);
  --color-data-sequential-positive-to-negative-step4: hsl(183,70%,57.450980392156865%);
  --color-data-sequential-positive-to-negative-step5: hsl(183,70%,57.450980392156865%);
  --color-data-sequential-positive-to-negative-step6: hsl(183,70%,56.73202614379085%);
  --color-data-sequential-positive-to-negative-step7: hsl(183,70%,55.88235294117648%);
  --color-data-sequential-positive-to-negative-step8: hsl(183,70%,55.16339869281046%);
  --color-data-sequential-positive-to-negative-step9: hsl(183,70%,54.31372549019608%);
  --color-data-categorical-category1-strong: hsl(183,70%,51.830065359477125%);
  --color-data-categorical-category1-subtle: hsl(183,70%,77.7124183006536%);
  --color-data-categorical-category2-strong: hsl(183,70%,58.627450980392155%);
  --color-data-categorical-category2-subtle: hsl(183,70%,77.97385620915031%);
  --color-data-categorical-category3-strong: hsl(183,70%,54.77124183006536%);
  --color-data-categorical-category3-subtle: hsl(183,70%,76.01307189542484%);
  --color-data-categorical-category4-strong: hsl(183,70%,58.10457516339869%);
  --color-data-categorical-category4-subtle: hsl(183,70%,76.79738562091504%);
  --color-data-categorical-category5-strong: hsl(183,70%,52.41830065359477%);
  --color-data-categorical-category5-subtle: hsl(183,70%,77.77777777777777%);
  --color-data-categorical-category6-strong: hsl(183,70%,57.58169934640523%);
  --color-data-categorical-category6-subtle: hsl(183,70%,77.18954248366012%);
  --color-data-categorical-category7-strong: hsl(183,70%,54.18300653594771%);
  --color-data-categorical-category7-subtle: hsl(183,70%,77.18954248366012%);
  --color-data-categorical-category8-strong: hsl(183,70%,56.9281045751634%);
  --color-data-categorical-category8-subtle: hsl(183,70%,77.45098039215685%);
  --color-data-categorical-category9-strong: hsl(183, 70%, 55%);
  --color-data-categorical-category9-subtle: hsl(183, 70%, 79%);
  --color-award-place1-light: hsl(183, 70%, 61.5%);
  --color-award-place1-mid: hsl(183, 70%, 65%);
  --color-award-place2-light: hsl(183, 70%, 71.5%);
  --color-award-place2-mid: hsl(183, 70%, 65%);
  --color-award-place3-light: hsl(183, 70%, 70%);
  --color-award-place3-mid: hsl(183, 70%, 65%);
  --color-confidence-high: hsl(183, 70%, 55.5%);
  --color-confidence-moderate: hsl(183, 70%, 55%);
  --color-confidence-low: hsl(183, 70%, 55%);
  --color-integrations-status-integrated-light: hsl(183, 70%, 75%);
  --color-integrations-status-integrated-mid: hsl(183, 70%, 45%);
  --color-integrations-status-partial-light: hsl(183, 70%, 75%);
  --color-integrations-status-partial-mid: hsl(183, 70%, 45%);
  --color-integrations-status-in-progress-light: hsl(183, 70%, 75%);
  --color-integrations-status-in-progress-mid: hsl(183, 70%, 45%);
  --color-integrations-status-not-integrated-light: hsl(183, 70%, 75%);
  --color-integrations-status-not-integrated-mid: hsl(183, 70%, 50.5%);
  --color-rating-rating1of5: hsl(183,70%,60.130718954248366%);
  --color-rating-rating2of5: hsl(183,70%,60.130718954248366%);
  --color-rating-rating3of5: hsl(183,70%,63.59477124183007%);
  --color-rating-rating4of5: hsl(183, 70%, 50%);
  --color-rating-rating5of5: hsl(183, 70%, 50%);
  --color-roles: hsl(183, 70%, 65%);
  --color-roles-host: hsl(183, 70%, 60%);
  --color-roles-guest: hsl(183, 70%, 60%);
  --color-roles-character: hsl(183, 70%, 65%);
  --color-has-guests-true: hsl(183, 70%, 55.5%);
  --color-has-guests-true-faint: hsl(183, 70%, 77.5%);
  --color-has-guests-true-on-selected: hsl(183, 70%, 55.5%);
  --color-has-guests-true-on-selected-dark-mode: hsl(183, 70%, 55.5%);
  --color-has-guests-true-dark-mode: hsl(183, 70%, 55.5%);
  --color-has-guests-false: hsl(183, 70%, 65%);
  --color-has-guests-false-faint: hsl(183, 70%, 78.5%);
  --color-has-guests-false-dark-mode: hsl(183, 70%, 45%);
  --color-has-guests-false-on-selected: hsl(183, 70%, 70%);
  --color-has-guests-false-on-selected-dark-mode: hsl(183, 70%, 45%);
  --color-is-active-true: hsl(183, 70%, 55.5%);
  --color-is-active-true-faint: hsl(183, 70%, 77.5%);
  --color-is-active-true-on-selected: hsl(183, 70%, 55.5%);
  --color-is-active-true-on-selected-dark-mode: hsl(183, 70%, 55.5%);
  --color-is-active-true-dark-mode: hsl(183, 70%, 55.5%);
  --color-is-active-false: hsl(183, 70%, 65%);
  --color-is-active-false-faint: hsl(183, 70%, 78.5%);
  --color-is-active-false-dark-mode: hsl(183, 70%, 45%);
  --color-is-active-false-on-selected: hsl(183, 70%, 70%);
  --color-is-active-false-on-selected-dark-mode: hsl(183, 70%, 45%);
  --color-pro-yellow: hsl(183,70%,70.91503267973856%);
  --color-pro-pink: hsl(183,70%,57.25490196078431%);
  --color-pro-blue: hsl(183,70%,63.0718954248366%);
  --color-claimed: hsl(183, 70%, 55%);
  --color-claimed-gradient-l0: hsl(183, 70%, 55%);
  --color-claimed-gradient-l100: hsl(183, 70%, 55.5%);
  --color-label-icon: hsl(183, 70%, 55%);
  --color-label-text: hsl(183, 70%, 55%);
  --color-political-left-strong: hsl(183,70%,49.47712418300654%);
  --color-political-left-subtle: hsl(183,70%,69.01960784313727%);
  --color-political-left-faint: hsl(183,70%,74.44444444444444%);
  --color-political-neutral-strong: hsl(183, 70%, 55%);
  --color-political-neutral-subtle: hsl(183, 70%, 75%);
  --color-political-neutral-faint: hsl(183, 70%, 77%);
  --color-political-right-strong: hsl(183, 70%, 55%);
  --color-political-right-subtle: hsl(183, 70%, 75%);
  --color-political-right-faint: hsl(183, 70%, 77%);
  --color-external-social-facebook: hsl(183,70%,53.39869281045752%);
  --color-external-social-youtube: hsl(183,70%,46.666666666666664%);
  --color-external-social-twitter: hsl(183,70%,62.810457516339866%);
  --color-external-social-linked-in: hsl(183,70%,49.93464052287582%);
  --color-external-social-twitch: hsl(183,70%,51.568627450980394%);
  --color-external-social-x: hsl(183,70%,30%);
  --color-external-social-x-hover: hsl(183,70%,45.88235294117647%);
/* dimension */
  --dimension-gap-text-to-component: 1rem;
  --dimension-gap-component-to-text: 1rem;
  --dimension-gap-text-to-text: 1rem;
  --dimension-gap-component-to-component: 1rem;
  --dimension-gap-section-to-section: 2rem;
/* elevation */
  --elevation-box-shadow-none: none;
  --elevation-box-shadow-raised: 0px 2px 10px rgba(0, 0, 0, 0.125);
  --elevation-box-shadow-inset: inset 0px 2px 10px rgba(0, 0, 0, 0.125);
/* text */
  --text-family-display: Avalon, sans-serif;
  --text-family-heading: Avalon, sans-serif;
  --text-family-body: Montserrat, sans-serif;
  --text-weight: 400;
  --text-weight-display: 700;
  --text-weight-heading: 700;
  --text-weight-body: 400;
  --text-size-display-lg: 6rem;
  --text-size-display-md: 4rem;
  --text-size-display-sm: 3rem;
  --text-size-heading-xl: 3rem;
  --text-size-heading-lg: 3rem;
  --text-size-heading-md: 1.5rem;
  --text-size-heading-sm: 1rem;
  --text-size-heading-xs: 1rem;
  --text-size-body-large: 1.25rem;
  --text-size-body-medium: 1rem;
  --text-size-body-small: 0.75rem;
/* line-height */
  --line-height-touching: 1.00;
  --line-height-tight: 1.25;
  --line-height-normal: 1.5;
  --line-height-loose: 1.75;
}